"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const BoardName_1 = require("@/common/boards/BoardName");
const RandomMAOptionType_1 = require("@/common/ma/RandomMAOptionType");
const Types_1 = require("@/common/turmoil/Types");
exports.default = vue_1.default.extend({
    name: 'game-setup-detail',
    props: {
        playerNumber: {
            type: Number,
        },
        gameOptions: {
            type: Object,
        },
        lastSoloGeneration: {
            type: Number,
        },
    },
    methods: {
        isPoliticalAgendasOn() {
            return (this.gameOptions.politicalAgendasExtension !== Types_1.AgendaStyle.STANDARD);
        },
        getBoardColorClass(boardName) {
            switch (boardName) {
                case BoardName_1.BoardName.THARSIS:
                    return 'game-config board-tharsis map';
                case BoardName_1.BoardName.HELLAS:
                    return 'game-config board-hellas map';
                case BoardName_1.BoardName.ELYSIUM:
                    return 'game-config board-elysium map';
                case BoardName_1.BoardName.AMAZONIS:
                    return 'game-config board-amazonis map';
                case BoardName_1.BoardName.ARABIA_TERRA:
                    return 'game-config board-arabia_terra map';
                case BoardName_1.BoardName.VASTITAS_BOREALIS:
                    return 'game-config board-vastitas_borealis map';
                case BoardName_1.BoardName.TERRA_CIMMERIA:
                    return 'game-config board-terra_cimmeria map';
                default:
                    return 'game-config board-other map';
            }
        },
    },
    computed: {
        RandomMAOptionType() {
            return RandomMAOptionType_1.RandomMAOptionType;
        },
    },
});
