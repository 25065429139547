"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const SoundManager_1 = require("@/client/utils/SoundManager");
const constants = require("@/common/constants");
const paths = require("@/common/app/paths");
const HTTPResponseCode = require("@/client/utils/HTTPResponseCode");
const Types_1 = require("@/common/Types");
let ui_update_timeout_id;
let documentTitleTimer;
exports.default = vue_1.default.extend({
    name: 'waiting-for',
    props: {
        playerView: {
            type: Object,
        },
        players: {
            type: Array,
        },
        settings: {
            type: Object,
        },
        waitingfor: {
            type: Object,
        },
    },
    data() {
        return {
            waitingForTimeout: this.settings.waitingForTimeout,
        };
    },
    methods: {
        animateTitle() {
            const sequence = '\u25D1\u25D2\u25D0\u25D3';
            const first = document.title[0];
            const position = sequence.indexOf(first);
            let next = sequence[0];
            if (position !== -1 && position < sequence.length - 1) {
                next = sequence[position + 1];
            }
            document.title = next + ' ' + this.$t(constants.APP_NAME);
        },
        onsave(out) {
            const xhr = new XMLHttpRequest();
            const root = this.$root;
            const showAlert = this.$root.showAlert;
            if (root.isServerSideRequestInProgress) {
                console.warn('Server request in progress');
                return;
            }
            root.isServerSideRequestInProgress = true;
            xhr.open('POST', paths.PLAYER_INPUT + '?id=' + this.playerView.id);
            xhr.responseType = 'json';
            xhr.onload = () => {
                if (xhr.status === HTTPResponseCode.OK) {
                    root.screen = 'empty';
                    root.playerView = xhr.response;
                    root.playerkey++;
                    root.screen = 'player-home';
                    if (this.playerView.game.phase === 'end' && window.location.pathname !== '/the-end') {
                        (window).location = (window).location;
                    }
                }
                else if (xhr.status === HTTPResponseCode.BAD_REQUEST && xhr.responseType === 'json') {
                    showAlert(xhr.response.message);
                }
                else {
                    showAlert('Unexpected response from server. Please try again.');
                }
                root.isServerSideRequestInProgress = false;
            };
            xhr.send(JSON.stringify(out));
            xhr.onerror = function () {
                root.isServerSideRequestInProgress = false;
            };
        },
        waitForUpdate() {
            const vueApp = this;
            const root = this.$root;
            clearTimeout(ui_update_timeout_id);
            const askForUpdate = () => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', paths.API_WAITING_FOR + window.location.search + '&gameAge=' + this.playerView.game.gameAge + '&undoCount=' + this.playerView.game.undoCount);
                xhr.onerror = function () {
                    root.showAlert('Unable to reach the server. The server may be restarting or down for maintenance.', () => vueApp.waitForUpdate());
                };
                xhr.onload = () => {
                    if (xhr.status === HTTPResponseCode.OK) {
                        const result = xhr.response;
                        if (result.result === 'GO') {
                            root.updatePlayer();
                            if (Notification.permission !== 'granted') {
                                Notification.requestPermission();
                            }
                            else if (Notification.permission === 'granted') {
                                const notificationOptions = {
                                    icon: '/favicon.ico',
                                    body: 'It\'s your turn!',
                                };
                                const notificationTitle = constants.APP_NAME;
                                try {
                                    new Notification(notificationTitle, notificationOptions);
                                }
                                catch (e) {
                                    if (!window.isSecureContext || !navigator.serviceWorker) {
                                        return;
                                    }
                                    navigator.serviceWorker.ready.then((registration) => {
                                        registration.showNotification(notificationTitle, notificationOptions);
                                    }).catch((err) => {
                                        console.warn('Failed to display notification with serviceWorker', err);
                                    });
                                }
                            }
                            const soundsEnabled = (0, PreferencesManager_1.getPreferences)().enable_sounds;
                            if (soundsEnabled)
                                SoundManager_1.SoundManager.playActivePlayerSound();
                            return;
                        }
                        else if (result.result === 'REFRESH') {
                            if ((0, Types_1.isPlayerId)(this.playerView.id)) {
                                root.updatePlayer();
                            }
                            else {
                                root.updateSpectator();
                            }
                            return;
                        }
                        vueApp.waitForUpdate();
                    }
                    else {
                        root.showAlert(`Received unexpected response from server (${xhr.status}). This is often due to the server restarting.`, () => vueApp.waitForUpdate());
                    }
                };
                xhr.responseType = 'json';
                xhr.send();
            };
            ui_update_timeout_id = window.setTimeout(askForUpdate, this.waitingForTimeout);
        },
    },
    mounted() {
        document.title = this.$t(constants.APP_NAME);
        window.clearInterval(documentTitleTimer);
        if (this.waitingfor === undefined) {
            this.waitForUpdate();
        }
        if (this.playerView.players.length > 1 && this.waitingfor !== undefined) {
            documentTitleTimer = window.setInterval(() => this.animateTitle(), 1000);
        }
    },
});
