"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Board_vue_1 = require("@/client/components/Board.vue");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const Milestones_vue_1 = require("@/client/components/Milestones.vue");
const Awards_vue_1 = require("@/client/components/Awards.vue");
const PlayersOverview_vue_1 = require("@/client/components/overview/PlayersOverview.vue");
const WaitingFor_vue_1 = require("@/client/components/WaitingFor.vue");
const Sidebar_vue_1 = require("@/client/components/Sidebar.vue");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
const LogPanel_vue_1 = require("@/client/components/LogPanel.vue");
const PlayerMixin_1 = require("@/client/mixins/PlayerMixin");
const Turmoil_vue_1 = require("@/client/components/turmoil/Turmoil.vue");
const utils_1 = require("@/common/utils/utils");
const PlanetaryTracks_vue_1 = require("@/client/components/pathfinders/PlanetaryTracks.vue");
const DynamicTitle_vue_1 = require("@/client/components/common/DynamicTitle.vue");
const SortableCards_vue_1 = require("@/client/components/SortableCards.vue");
const TopBar_vue_1 = require("@/client/components/TopBar.vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const KeyboardNavigation_1 = require("@/client/components/KeyboardNavigation");
const MoonBoard_vue_1 = require("@/client/components/moon/MoonBoard.vue");
const Phase_1 = require("@/common/Phase");
const StackedCards_vue_1 = require("@/client/components/StackedCards.vue");
const CardType_1 = require("@/common/cards/CardType");
const TileView_1 = require("./board/TileView");
class TerraformedAlertDialog {
}
TerraformedAlertDialog.shouldAlert = true;
exports.default = vue_1.default.extend({
    name: 'player-home',
    data() {
        const preferences = (0, PreferencesManager_1.getPreferences)();
        return {
            showActiveCards: !preferences.hide_active_cards,
            showAutomatedCards: !preferences.hide_automated_cards,
            showEventCards: !preferences.hide_event_cards,
            tileView: 'show',
        };
    },
    watch: {
        hide_active_cards() {
            PreferencesManager_1.PreferencesManager.INSTANCE.set('hide_active_cards', !this.showActiveCards);
        },
        hide_automated_cards() {
            PreferencesManager_1.PreferencesManager.INSTANCE.set('hide_automated_cards', !this.showAutomatedCards);
        },
        hide_event_cards() {
            PreferencesManager_1.PreferencesManager.INSTANCE.set('hide_event_cards', !this.showEventCards);
        },
    },
    props: {
        playerView: {
            type: Object,
        },
        settings: {
            type: Object,
        },
    },
    computed: {
        thisPlayer() {
            return this.playerView.thisPlayer;
        },
        game() {
            return this.playerView.game;
        },
        CardType() {
            return CardType_1.CardType;
        },
    },
    components: {
        'board': Board_vue_1.default,
        DynamicTitle: DynamicTitle_vue_1.default,
        Card: Card_vue_1.default,
        'players-overview': PlayersOverview_vue_1.default,
        'waiting-for': WaitingFor_vue_1.default,
        Milestones: Milestones_vue_1.default,
        Awards: Awards_vue_1.default,
        'sidebar': Sidebar_vue_1.default,
        'colony': Colony_vue_1.default,
        'log-panel': LogPanel_vue_1.default,
        'turmoil': Turmoil_vue_1.default,
        'sortable-cards': SortableCards_vue_1.default,
        'top-bar': TopBar_vue_1.default,
        MoonBoard: MoonBoard_vue_1.default,
        PlanetaryTracks: PlanetaryTracks_vue_1.default,
        'stacked-cards': StackedCards_vue_1.default,
    },
    mixins: [PlayerMixin_1.PlayerMixin],
    methods: Object.assign(Object.assign({}, PlayerMixin_1.PlayerMixin.methods), { navigatePage(event) {
            const inputSource = event.target;
            if (inputSource.nodeName.toLowerCase() !== 'input') {
                let id = undefined;
                switch (event.code) {
                    case KeyboardNavigation_1.KeyboardNavigation.GAMEBOARD:
                        id = 'shortkey-board';
                        break;
                    case KeyboardNavigation_1.KeyboardNavigation.PLAYERSOVERVIEW:
                        id = 'shortkey-playersoverview';
                        break;
                    case KeyboardNavigation_1.KeyboardNavigation.HAND:
                        id = 'shortkey-hand';
                        break;
                    case KeyboardNavigation_1.KeyboardNavigation.COLONIES:
                        id = 'shortkey-colonies';
                        break;
                    default:
                        return;
                }
                const el = document.getElementById(id);
                if (el) {
                    event.preventDefault();
                    el.scrollIntoView({ block: 'center', inline: 'center', behavior: 'auto' });
                }
            }
        },
        isPlayerActing(playerView) {
            return playerView.players.length > 1 && playerView.waitingFor !== undefined;
        }, getPlayerCssForTurnOrder: (player, highlightActive) => {
            const classes = ['highlighter_box'];
            if (highlightActive) {
                if (player.needsToDraft || (player.needsToDraft === undefined && player.isActive)) {
                    classes.push('player_is_active');
                }
                classes.push((0, utils_1.playerColorClass)(player.color, 'bg'));
            }
            return classes.join(' ');
        }, getFleetsCountRange(player) {
            const fleetsRange = [];
            for (let i = 0; i < player.fleetSize - player.tradesThisGeneration; i++) {
                fleetsRange.push(i);
            }
            return fleetsRange;
        },
        toggle(type) {
            switch (type) {
                case 'ACTIVE':
                    this.showActiveCards = !this.showActiveCards;
                    break;
                case 'AUTOMATED':
                    this.showAutomatedCards = !this.showAutomatedCards;
                    break;
                case 'EVENT':
                    this.showEventCards = !this.showEventCards;
                    break;
            }
        },
        cycleTileView() {
            this.tileView = (0, TileView_1.nextTileView)(this.tileView);
        },
        isVisible(type) {
            switch (type) {
                case 'ACTIVE':
                    return this.showActiveCards;
                case 'AUTOMATED':
                    return this.showAutomatedCards;
                case 'EVENT':
                    return this.showEventCards;
            }
            return false;
        },
        isInitialDraftingPhase() {
            return (this.game.phase === Phase_1.Phase.INITIALDRAFTING) && this.game.gameOptions.initialDraftVariant;
        },
        isCorporationDraftingPhase() {
            return (this.game.phase === Phase_1.Phase.CORPORATIONDRAFTING) && this.game.gameOptions.corporationsDraft;
        },
        getToggleLabel(hideType) {
            if (hideType === 'ACTIVE') {
                return (this.showActiveCards ? '✔' : '');
            }
            else if (hideType === 'AUTOMATED') {
                return (this.showAutomatedCards ? '✔' : '');
            }
            else if (hideType === 'EVENT') {
                return (this.showEventCards ? '✔' : '');
            }
            else {
                return '';
            }
        },
        getHideButtonClass(hideType) {
            const prefix = 'hiding-card-button ';
            if (hideType === 'ACTIVE') {
                return prefix + (this.showActiveCards ? 'active' : 'active-transparent');
            }
            else if (hideType === 'AUTOMATED') {
                return prefix + (this.showAutomatedCards ? 'automated' : 'automated-transparent');
            }
            else if (hideType === 'EVENT') {
                return prefix + (this.showEventCards ? 'event' : 'event-transparent');
            }
            else {
                return '';
            }
        } }),
    destroyed() {
        window.removeEventListener('keydown', this.navigatePage);
    },
    mounted() {
        window.addEventListener('keydown', this.navigatePage);
        if (this.game.isTerraformed && TerraformedAlertDialog.shouldAlert && (0, PreferencesManager_1.getPreferences)().show_alerts) {
            alert('Mars is Terraformed!');
            TerraformedAlertDialog.shouldAlert = false;
        }
    },
});
