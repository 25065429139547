"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const CardType_1 = require("@/common/cards/CardType");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const ClientGlobalEventManifest_1 = require("@/client/turmoil/ClientGlobalEventManifest");
const GlobalEvent_vue_1 = require("@/client/components/turmoil/GlobalEvent.vue");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
const AllColonies_1 = require("@/common/colonies/AllColonies");
const PreferencesIcon_vue_1 = require("@/client/components/PreferencesIcon.vue");
const GameModule_1 = require("@/common/cards/GameModule");
const Tag_1 = require("@/common/cards/Tag");
const ClientColonyManifest_1 = require("@/client/colonies/ClientColonyManifest");
const ICardRenderDescription_1 = require("@/common/cards/render/ICardRenderDescription");
const Types_1 = require("@/common/cards/render/Types");
const CardRenderItemType_1 = require("@/common/cards/render/CardRenderItemType");
const i18n_1 = require("@/client/directives/i18n");
const MilestoneName_1 = require("@/common/ma/MilestoneName");
const AwardName_1 = require("@/common/ma/AwardName");
const Milestone_vue_1 = require("@/client/components/Milestone.vue");
const Award_vue_1 = require("@/client/components/Award.vue");
const moduleAbbreviations = {
    base: 'b',
    corpera: 'c',
    prelude: 'p',
    venus: 'v',
    colonies: 'C',
    turmoil: 't',
    community: '*',
    promo: 'r',
    ares: 'a',
    moon: 'm',
    pathfinders: 'P',
    leader: 'l',
};
const ALL_MODULES = 'bcpvCt*ramP';
function buildSearchIndex(map) {
    let entries = [];
    function add(text) {
        entries.push((0, i18n_1.translateText)(text).toLocaleUpperCase());
    }
    function process(component) {
        if ((0, Types_1.isICardRenderItem)(component)) {
            if (component.type === CardRenderItemType_1.CardRenderItemType.TEXT && component.text !== undefined) {
                add(component.text);
            }
        }
        else if ((0, Types_1.isICardRenderRoot)(component) ||
            (0, Types_1.isICardRenderCorpBoxEffect)(component) ||
            (0, Types_1.isICardRenderCorpBoxAction)(component) ||
            (0, Types_1.isICardRenderEffect)(component) ||
            (0, Types_1.isICardRenderProductionBox)(component)) {
            component.rows.forEach((row) => {
                row.forEach((item) => {
                    if (typeof (item) === 'string') {
                        add(item);
                    }
                    else if (item !== undefined) {
                        process(item);
                    }
                });
            });
        }
    }
    for (const card of (0, ClientCardManifest_1.getCards)(() => true)) {
        entries = [];
        const metadata = card.metadata;
        const description = metadata.description;
        if (description !== undefined) {
            const text = (0, ICardRenderDescription_1.isIDescription)(description) ? description.text : description;
            add(text);
        }
        if (metadata.renderData) {
            process(metadata.renderData);
        }
        map.set('card:' + card.name, [...entries]);
    }
    for (const globalEventName of (0, ClientGlobalEventManifest_1.allGlobalEventNames)()) {
        const globalEvent = (0, ClientGlobalEventManifest_1.getGlobalEventOrThrow)(globalEventName);
        entries = [];
        add(globalEvent.name);
        add(globalEvent.description);
        process(globalEvent.renderData);
        map.set('globalEvent:' + globalEvent.name, [...entries]);
    }
}
exports.default = vue_1.default.extend({
    name: 'debug-ui',
    components: {
        Card: Card_vue_1.default,
        GlobalEvent: GlobalEvent_vue_1.default,
        Colony: Colony_vue_1.default,
        Milestone: Milestone_vue_1.default,
        Award: Award_vue_1.default,
        PreferencesIcon: PreferencesIcon_vue_1.default,
    },
    data() {
        return {
            filterText: '',
            fullFilter: false,
            expansions: {
                base: true,
                corpera: true,
                prelude: true,
                venus: true,
                colonies: true,
                turmoil: true,
                community: true,
                ares: true,
                moon: true,
                promo: true,
                pathfinders: true,
                leader: true,
            },
            types: {
                event: true,
                active: true,
                automated: true,
                prelude: true,
                corporation: true,
                standard_project: true,
                standard_action: false,
                proxy: false,
                globalEvents: true,
                colonyTiles: true,
                milestones: true,
                awards: true,
                leader: true,
            },
            tags: {
                building: true,
                space: true,
                science: true,
                power: true,
                earth: true,
                jovian: true,
                venus: true,
                plant: true,
                microbe: true,
                animal: true,
                city: true,
                moon: true,
                mars: true,
                wild: true,
                event: true,
                clone: true,
                none: true,
            },
            searchIndex: new Map(),
        };
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const searchString = urlParams.get('search');
        if (searchString) {
            this.filterText = searchString;
        }
        const modules = urlParams.get('m') || ALL_MODULES;
        GameModule_1.GAME_MODULES.forEach((module) => {
            return this.expansions[module] = modules.includes(moduleAbbreviations[module]);
        });
        buildSearchIndex(this.searchIndex);
    },
    computed: {
        allModules() {
            return GameModule_1.GAME_MODULES;
        },
        allTypes() {
            return [
                CardType_1.CardType.EVENT,
                CardType_1.CardType.ACTIVE,
                CardType_1.CardType.AUTOMATED,
                CardType_1.CardType.PRELUDE,
                CardType_1.CardType.CORPORATION,
                CardType_1.CardType.STANDARD_PROJECT,
                'colonyTiles',
                'globalEvents',
                'milestones',
                'awards',
            ];
        },
        allTags() {
            const results = [];
            for (const tag in Tag_1.Tag) {
                if (Object.prototype.hasOwnProperty.call(Tag_1.Tag, tag)) {
                    results.push(Tag_1.Tag[tag]);
                }
            }
            return results.concat('none');
        },
        allMilestoneNames() {
            return MilestoneName_1.milestoneNames;
        },
        allAwardNames() {
            return AwardName_1.awardNames;
        },
    },
    methods: {
        updateUrl(search) {
            if (window.history.pushState) {
                let url = window.location.protocol + '//' + window.location.host + window.location.pathname;
                if (search) {
                    url = url + '?search=' + search;
                }
                let m = GameModule_1.GAME_MODULES.map((module) => {
                    return this.expansions[module] ? moduleAbbreviations[module] : '';
                }).join('');
                if (m === '')
                    m = '-';
                if (m !== ALL_MODULES) {
                    url = url + '?m=' + m;
                }
                window.history.pushState({ path: url }, '', url);
            }
        },
        invertExpansions() {
            GameModule_1.GAME_MODULES.forEach((module) => this.$data.expansions[module] = !this.$data.expansions[module]);
        },
        invertTags() {
            this.allTags.forEach((tag) => this.$data.tags[tag] = !this.$data.tags[tag]);
        },
        invertTypes() {
            this.allTypes.forEach((type) => this.$data.types[type] = !this.$data.types[type]);
        },
        sort(names) {
            const translated = names.map((name) => ({ name: name, text: (0, i18n_1.translateText)(name) }));
            translated.sort((a, b) => a.text.localeCompare(b.text));
            return translated.map((e) => e.name);
        },
        getAllStandardProjectCards() {
            const names = (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.STANDARD_PROJECT)).map(ClientCardManifest_1.toName);
            return this.sort(names);
        },
        getAllProjectCards() {
            const names = [];
            names.push(...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.AUTOMATED)).map(ClientCardManifest_1.toName));
            names.push(...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.ACTIVE)).map(ClientCardManifest_1.toName));
            names.push(...(0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.EVENT)).map(ClientCardManifest_1.toName));
            return this.sort(names);
        },
        getAllCorporationCards() {
            const names = (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.CORPORATION)).map(ClientCardManifest_1.toName);
            return this.sort(names);
        },
        getAllPreludeCards() {
            const names = (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.PRELUDE)).map(ClientCardManifest_1.toName);
            return this.sort(names);
        },
        getAllGlobalEvents() {
            return this.sort(Array.from((0, ClientGlobalEventManifest_1.allGlobalEventNames)()));
        },
        getAllColonyNames() {
            return AllColonies_1.OFFICIAL_COLONY_NAMES.concat(AllColonies_1.COMMUNITY_COLONY_NAMES).concat(AllColonies_1.PATHFINDERS_COLONY_NAMES);
        },
        getGlobalEventModel(globalEventName) {
            return (0, ClientGlobalEventManifest_1.getGlobalEventModel)(globalEventName);
        },
        filter(name, type) {
            const filterText = this.$data.filterText.toLocaleUpperCase();
            if (filterText.length === 0) {
                return true;
            }
            if (this.fullFilter) {
                const detail = this.searchIndex.get(`${type}:${name}`);
                if (detail !== undefined) {
                    if (detail.some((entry) => entry.includes(filterText))) {
                        return true;
                    }
                }
                return false;
            }
            else {
                return name.toLocaleUpperCase().includes(filterText);
            }
        },
        expansionIconClass(expansion) {
            if (expansion === 'base')
                return '';
            switch (expansion) {
                case 'corpera': return 'expansion-icon-CE';
                case 'colonies': return 'expansion-icon-colony';
                case 'moon': return 'expansion-icon-themoon';
                default: return `expansion-icon-${expansion}`;
            }
        },
        expansionName(expansion) {
            switch (expansion) {
                case 'base': return 'Base';
                case 'corpera': return 'Corporate Era';
                case 'prelude': return 'Prelude';
                case 'venus': return 'Venus Next';
                case 'colonies': return 'Colonies';
                case 'turmoil': return 'Turmoil';
                case 'promo': return 'Promos';
                case 'ares': return 'Ares';
                case 'community': return 'Community';
                case 'moon': return 'The Moon';
                case 'pathfinders': return 'Pathfinders';
                case 'leader': return 'CEOs';
            }
        },
        filterByTags(card) {
            if (card.tags.length === 0) {
                return this.tags['none'] === true;
            }
            let matches = false;
            for (const tag of card.tags) {
                if (this.tags[tag])
                    matches = true;
            }
            return matches;
        },
        showCard(cardName) {
            if (!this.filter(cardName, 'card'))
                return false;
            const card = (0, ClientCardManifest_1.getCard)(cardName);
            if (card === undefined) {
                return false;
            }
            if (!this.filterByTags(card))
                return false;
            if (!this.types[card.cardType])
                return false;
            return this.expansions[card.module] === true;
        },
        showGlobalEvent(name) {
            if (!this.filter(name, 'globalEvent'))
                return false;
            const globalEvent = (0, ClientGlobalEventManifest_1.getGlobalEvent)(name);
            return globalEvent !== undefined && this.expansions[globalEvent.module] === true;
        },
        showColony(name) {
            var _a;
            if (!this.filter(name, 'colony'))
                return false;
            const colony = (0, ClientColonyManifest_1.getColony)(name);
            return colony !== undefined && this.expansions[(_a = colony.module) !== null && _a !== void 0 ? _a : 'base'] === true;
        },
        getLanguageCssClass() {
            const language = (0, PreferencesManager_1.getPreferences)().lang;
            return 'language-' + language;
        },
        colonyModel(colonyName) {
            return {
                colonies: [],
                isActive: false,
                name: colonyName,
                trackPosition: 0,
                visitor: undefined,
            };
        },
        milestoneModel(milestoneName) {
            return {
                name: milestoneName,
                player_name: '',
                player_color: '',
                scores: [],
            };
        },
        awardModel(awardName) {
            return {
                name: awardName,
                player_name: '',
                player_color: '',
                scores: [],
            };
        },
    },
});
