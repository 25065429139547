"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tag = void 0;
var Tag;
(function (Tag) {
    Tag["BUILDING"] = "building";
    Tag["SPACE"] = "space";
    Tag["SCIENCE"] = "science";
    Tag["POWER"] = "power";
    Tag["EARTH"] = "earth";
    Tag["JOVIAN"] = "jovian";
    Tag["VENUS"] = "venus";
    Tag["PLANT"] = "plant";
    Tag["MICROBE"] = "microbe";
    Tag["ANIMAL"] = "animal";
    Tag["CITY"] = "city";
    Tag["MOON"] = "moon";
    Tag["MARS"] = "mars";
    Tag["WILD"] = "wild";
    Tag["EVENT"] = "event";
    Tag["CLONE"] = "clone";
})(Tag = exports.Tag || (exports.Tag = {}));
