"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PlayerResources_vue_1 = require("@/client/components/overview/PlayerResources.vue");
const PlayerTags_vue_1 = require("@/client/components/overview/PlayerTags.vue");
const PlayerStatus_vue_1 = require("@/client/components/overview/PlayerStatus.vue");
const utils_1 = require("@/common/utils/utils");
const utils_2 = require("@/common/utils/utils");
const PlayerMixin_1 = require("@/client/mixins/PlayerMixin");
const Button_vue_1 = require("@/client/components/common/Button.vue");
const CardType_1 = require("@/common/cards/CardType");
const isPinned = (root, playerIndex) => {
    return root.getVisibilityState('pinned_player_' + playerIndex);
};
const showPlayerData = (root, playerIndex) => {
    root.setVisibilityState('pinned_player_' + playerIndex, true);
};
exports.default = vue_1.default.extend({
    name: 'PlayerInfo',
    props: {
        player: {
            type: Object,
        },
        playerView: {
            type: Object,
        },
        firstForGen: {
            type: Boolean,
            default: false,
        },
        actionLabel: {
            type: String,
            default: '',
        },
        playerIndex: {
            type: Number,
        },
        hideZeroTags: {
            type: Boolean,
            default: false,
        },
        isTopBar: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Button: Button_vue_1.default,
        PlayerResources: PlayerResources_vue_1.default,
        PlayerTags: PlayerTags_vue_1.default,
        'player-status': PlayerStatus_vue_1.default,
    },
    mixins: [PlayerMixin_1.PlayerMixin],
    computed: {
        tooltipCss() {
            return 'tooltip tooltip-' + (this.isTopBar ? 'bottom' : 'top');
        },
    },
    methods: {
        pinPlayer() {
            let hiddenPlayersIndexes = [];
            const playerPinned = isPinned(this.$root, this.playerIndex);
            hiddenPlayersIndexes = (0, utils_2.range)(this.playerView.players.length - 1);
            if (!playerPinned) {
                showPlayerData(this.$root, this.playerIndex);
                hiddenPlayersIndexes = hiddenPlayersIndexes.filter((index) => index !== this.playerIndex);
            }
            for (let i = 0; i < hiddenPlayersIndexes.length; i++) {
                if (hiddenPlayersIndexes.includes(i)) {
                    this.$root.setVisibilityState('pinned_player_' + i, false);
                }
            }
        },
        buttonLabel() {
            return isPinned(this.$root, this.playerIndex) ? 'hide' : 'show';
        },
        togglePlayerDetails() {
            var _a;
            if (this.player.color === ((_a = this.playerView.thisPlayer) === null || _a === void 0 ? void 0 : _a.color)) {
                const el = document.getElementsByClassName('sidebar_icon--cards')[0];
                el.click();
                return;
            }
            this.pinPlayer();
        },
        getClasses() {
            return `player-info ${(0, utils_1.playerColorClass)(this.player.color, 'bg_transparent')}`;
        },
        numberOfPlayedCards() {
            return this.player.tableau.length;
        },
        availableBlueActionCount() {
            return this.player.availableBlueCardActionCount;
        },
        corporationCardName() {
            const card = this.player.tableau[0];
            if ((card === null || card === void 0 ? void 0 : card.cardType) !== CardType_1.CardType.CORPORATION)
                return undefined;
            return card.name;
        },
    },
});
