"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardResource_1 = require("@/common/CardResource");
exports.default = vue_1.default.extend({
    name: 'CardResourceCounter',
    props: {
        amount: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    computed: {
        getClass() {
            switch (this.type) {
                case CardResource_1.CardResource.ANIMAL:
                    return 'card-resource-animal';
                case CardResource_1.CardResource.MICROBE:
                    return 'card-resource-microbe';
                case CardResource_1.CardResource.FIGHTER:
                    return 'card-resource-fighter';
                case CardResource_1.CardResource.SCIENCE:
                    return 'card-resource-science';
                case CardResource_1.CardResource.FLOATER:
                    return 'card-resource-floater';
                case CardResource_1.CardResource.ASTEROID:
                    return 'card-resource-asteroid';
                case CardResource_1.CardResource.PRESERVATION:
                    return 'card-resource-preservation';
                case CardResource_1.CardResource.CAMP:
                    return 'card-resource-camp';
                case CardResource_1.CardResource.DISEASE:
                    return 'card-resource-disease';
                case CardResource_1.CardResource.RESOURCE_CUBE:
                    return 'card-resource-cube';
                case CardResource_1.CardResource.DATA:
                    return 'card-resource-data';
                case CardResource_1.CardResource.SYNDICATE_FLEET:
                    return 'card-resource-syndicate-fleet';
                case CardResource_1.CardResource.VENUSIAN_HABITAT:
                    return 'card-resource-venusian-habitat';
                case CardResource_1.CardResource.SPECIALIZED_ROBOT:
                    return 'card-resource-specialized-robot';
                case CardResource_1.CardResource.SEED:
                    return 'card-resource-seed';
                case CardResource_1.CardResource.AGENDA:
                    return 'card-resource-agenda';
                case CardResource_1.CardResource.ORBITAL:
                    return 'card-resource-orbital';
                default:
                    return '';
            }
        },
    },
});
