"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRenderSymbolType_1 = require("@/common/cards/render/CardRenderSymbolType");
const Size_1 = require("@/common/cards/render/Size");
const configs = {
    [CardRenderSymbolType_1.CardRenderSymbolType.ASTERIX]: { class: 'card-asterix' },
    [CardRenderSymbolType_1.CardRenderSymbolType.MINUS]: { class: 'card-minus' },
    [CardRenderSymbolType_1.CardRenderSymbolType.PLUS]: { class: 'card-plus', sizes: [Size_1.Size.SMALL] },
    [CardRenderSymbolType_1.CardRenderSymbolType.OR]: { class: 'card-or', sizes: [Size_1.Size.SMALL, Size_1.Size.TINY] },
    [CardRenderSymbolType_1.CardRenderSymbolType.COLON]: { class: 'card-colon' },
    [CardRenderSymbolType_1.CardRenderSymbolType.ARROW]: { class: 'card-arrow', sizes: [Size_1.Size.SMALL] },
    [CardRenderSymbolType_1.CardRenderSymbolType.NBSP]: { class: 'card-nbsp' },
    [CardRenderSymbolType_1.CardRenderSymbolType.VSPACE]: { class: 'card-vspace', sizes: [Size_1.Size.SMALL, Size_1.Size.MEDIUM, Size_1.Size.LARGE] },
    [CardRenderSymbolType_1.CardRenderSymbolType.SLASH]: { class: 'card-slash', sizes: [Size_1.Size.SMALL] },
    [CardRenderSymbolType_1.CardRenderSymbolType.EQUALS]: { class: 'card-equals' },
    [CardRenderSymbolType_1.CardRenderSymbolType.SURVEY_MISSION]: { class: 'card-survey-mission' },
    [CardRenderSymbolType_1.CardRenderSymbolType.EMPTY]: { class: '' },
    [CardRenderSymbolType_1.CardRenderSymbolType.BRACKET_OPEN]: { class: '' },
    [CardRenderSymbolType_1.CardRenderSymbolType.BRACKET_CLOSE]: { class: '' },
};
function sizeToString(size) {
    switch (size) {
        case Size_1.Size.TINY: return 'tiny';
        case Size_1.Size.SMALL: return 'small';
        case Size_1.Size.MEDIUM: return 'medium';
        case Size_1.Size.LARGE: return 'large';
    }
}
exports.default = vue_1.default.extend({
    name: 'CardRenderSymbolComponent',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getClasses() {
            var _a;
            const type = this.item.type;
            const size = this.item.size;
            const classes = ['card-special'];
            if (type === CardRenderSymbolType_1.CardRenderSymbolType.ARROW) {
                if (size === Size_1.Size.SMALL) {
                    classes.push('card-red-arrow--small');
                }
                else {
                    classes.push('card-red-arrow');
                }
            }
            else {
                const config = configs[type];
                classes.push(config.class);
                if ((_a = config.sizes) === null || _a === void 0 ? void 0 : _a.includes(size)) {
                    classes.push(`${config.class}--${sizeToString(size)}`);
                }
            }
            return classes.join(' ');
        },
        getContent() {
            return this.item.isIcon ? '' : this.item.type;
        },
    },
});
