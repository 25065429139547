"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Button_vue_1 = require("@/client/components/common/Button.vue");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const CardName_1 = require("@/common/cards/CardName");
const constants = require("@/common/constants");
const SelectCard_vue_1 = require("@/client/components/SelectCard.vue");
const ConfirmDialog_vue_1 = require("@/client/components/common/ConfirmDialog.vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const Tag_1 = require("@/common/cards/Tag");
const CardType_1 = require("@/common/cards/CardType");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
exports.default = vue_1.default.extend({
    name: 'SelectInitialCards',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
        preferences: {
            type: Object,
            default: () => PreferencesManager_1.PreferencesManager.INSTANCE.values(),
        },
    },
    components: {
        Button: Button_vue_1.default,
        SelectCard: SelectCard_vue_1.default,
        'confirm-dialog': ConfirmDialog_vue_1.default,
        Colony: Colony_vue_1.default,
    },
    data() {
        return {
            selectedCards: [],
            selectedCorporations: [],
            selectedPreludes: [],
            valid: false,
            warning: undefined,
        };
    },
    methods: {
        noop() {
            throw new Error('should not be called');
        },
        getAfterPreludes() {
            var _a, _b, _c;
            let result = 0;
            for (const prelude of this.selectedPreludes) {
                const card = (0, ClientCardManifest_1.getCardOrThrow)(prelude);
                result += (_a = card.startingMegaCredits) !== null && _a !== void 0 ? _a : 0;
                switch (this.selectedCorporations.length === 1 ? this.selectedCorporations[0] : undefined) {
                    case CardName_1.CardName.MANUTECH:
                        result += (_c = (_b = card.productionBox) === null || _b === void 0 ? void 0 : _b.megacredits) !== null && _c !== void 0 ? _c : 0;
                        break;
                    case CardName_1.CardName.THARSIS_REPUBLIC:
                        switch (prelude) {
                            case CardName_1.CardName.SELF_SUFFICIENT_SETTLEMENT:
                            case CardName_1.CardName.EARLY_SETTLEMENT:
                            case CardName_1.CardName.STRATEGIC_BASE_PLANNING:
                                result += 3;
                                break;
                        }
                        break;
                    case CardName_1.CardName.PHARMACY_UNION:
                        const tags = card.tags.filter((tag) => tag === Tag_1.Tag.MICROBE).length;
                        result -= (4 * tags);
                        break;
                    case CardName_1.CardName.SPLICE:
                        const microbeTags = card.tags.filter((tag) => tag === Tag_1.Tag.MICROBE).length;
                        result += (2 * microbeTags);
                        break;
                    case CardName_1.CardName.APHRODITE:
                        switch (prelude) {
                            case CardName_1.CardName.VENUS_FIRST:
                            case CardName_1.CardName.VENUS_FIRST_PATHFINDERS:
                                result += 4;
                                break;
                            case CardName_1.CardName.HYDROGEN_BOMBARDMENT:
                                result += 2;
                                break;
                        }
                        break;
                    case CardName_1.CardName.LUNA_FIRST_INCORPORATED:
                        switch (prelude) {
                            case CardName_1.CardName.FIRST_LUNAR_SETTLEMENT:
                            case CardName_1.CardName.CORE_MINE:
                            case CardName_1.CardName.BASIC_INFRASTRUCTURE:
                                result += 1;
                                break;
                            case CardName_1.CardName.MINING_COMPLEX:
                                result += 2;
                                break;
                        }
                        break;
                    case CardName_1.CardName.POLARIS:
                        switch (prelude) {
                            case CardName_1.CardName.AQUIFER_TURBINES:
                            case CardName_1.CardName.POLAR_INDUSTRIES:
                                result += 4;
                                break;
                            case CardName_1.CardName.GREAT_AQUIFER:
                                result += 8;
                                break;
                        }
                        break;
                }
            }
            return result;
        },
        getStartingMegacredits() {
            var _a;
            if (this.selectedCorporations.length !== 1) {
                return NaN;
            }
            const corpName = this.selectedCorporations[0];
            const corporation = (0, ClientCardManifest_1.getCardOrThrow)(corpName);
            let starting = (_a = corporation.startingMegaCredits) !== null && _a !== void 0 ? _a : 0;
            const cardCost = corporation.cardCost === undefined ? constants.CARD_COST : corporation.cardCost;
            starting -= this.selectedCards.length * cardCost;
            return starting;
        },
        saveIfConfirmed() {
            const projectCards = this.selectedCards.filter((name) => { var _a; return ((_a = (0, ClientCardManifest_1.getCard)(name)) === null || _a === void 0 ? void 0 : _a.cardType) !== CardType_1.CardType.PRELUDE; });
            let showAlert = false;
            if (this.preferences.show_alerts && projectCards.length === 0)
                showAlert = true;
            if (showAlert) {
                this.$refs.confirmation.show();
            }
            else {
                this.saveData();
            }
        },
        saveData() {
            const result = {
                type: 'and',
                responses: [],
            };
            if (this.selectedCorporations.length === 1) {
                result.responses.push({
                    type: 'card',
                    cards: [this.selectedCorporations[0]],
                });
            }
            if (this.hasPrelude) {
                result.responses.push({
                    type: 'card',
                    cards: this.selectedPreludes,
                });
            }
            result.responses.push({
                type: 'card',
                cards: this.selectedCards,
            });
            this.onsave(result);
        },
        cardsChanged(cards) {
            this.selectedCards = cards;
            this.validate();
        },
        corporationChanged(cards) {
            this.selectedCorporations = cards;
            this.validate();
        },
        preludesChanged(cards) {
            this.selectedPreludes = cards;
            this.validate();
        },
        calcuateWarning() {
            this.warning = undefined;
            if (this.selectedCorporations.length === 0) {
                this.warning = 'Select a corporation';
                return false;
            }
            if (this.selectedCorporations.length > 1) {
                this.warning = 'You selected too many corporations';
                return false;
            }
            if (this.hasPrelude) {
                if (this.selectedPreludes.length < 2) {
                    this.warning = 'Select 2 preludes';
                    return false;
                }
                if (this.selectedPreludes.length > 2) {
                    this.warning = 'You selected too many preludes';
                    return false;
                }
            }
            if (this.selectedCards.length === 0) {
                this.warning = 'You haven\'t selected any project cards';
                return true;
            }
            return true;
        },
        validate() {
            this.valid = this.calcuateWarning();
        },
        confirmSelection() {
            this.saveData();
        },
    },
    computed: {
        playerCanChooseAridor() {
            return this.playerView.dealtCorporationCards.some((card) => card.name === CardName_1.CardName.ARIDOR);
        },
        hasPrelude() {
            var _a;
            return ((_a = this.playerinput.options) === null || _a === void 0 ? void 0 : _a.length) === 3;
        },
        corpCardOption() {
            const option = getOption(this.playerinput.options, 0);
            if ((0, PreferencesManager_1.getPreferences)().experimental_ui) {
                option.min = 1;
                option.max = undefined;
            }
            return option;
        },
        preludeCardOption() {
            const option = getOption(this.playerinput.options, 1);
            if ((0, PreferencesManager_1.getPreferences)().experimental_ui) {
                option.max = undefined;
            }
            return option;
        },
        projectCardOption() {
            var _a;
            return getOption(this.playerinput.options, ((_a = this.playerinput.options) === null || _a === void 0 ? void 0 : _a.length) === 3 ? 2 : 1);
        },
    },
    mounted() {
        this.validate();
    },
});
function getOption(options, idx) {
    const option = options === null || options === void 0 ? void 0 : options[idx];
    if (option === undefined) {
        throw new Error('invalid input, missing option');
    }
    return option;
}
