"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PlayerInputType_1 = require("@/common/input/PlayerInputType");
const AndOptions_vue_1 = require("@/client/components/AndOptions.vue");
const OrOptions_vue_1 = require("@/client/components/OrOptions.vue");
const SelectAmount_vue_1 = require("@/client/components/SelectAmount.vue");
const SelectCard_vue_1 = require("@/client/components/SelectCard.vue");
const SelectPayment_vue_1 = require("@/client/components/SelectPayment.vue");
const SelectProjectCardToPlay_vue_1 = require("@/client/components/SelectProjectCardToPlay.vue");
const SelectInitialCards_vue_1 = require("@/client/components/SelectInitialCards.vue");
const SelectOption_vue_1 = require("@/client/components/SelectOption.vue");
const SelectPlayer_vue_1 = require("@/client/components/SelectPlayer.vue");
const SelectSpace_vue_1 = require("@/client/components/SelectSpace.vue");
const SelectPartyPlayer_vue_1 = require("@/client/components/SelectPartyPlayer.vue");
const SelectPartyToSendDelegate_vue_1 = require("@/client/components/SelectPartyToSendDelegate.vue");
const SelectColony_vue_1 = require("@/client/components/SelectColony.vue");
const SelectProductionToLose_vue_1 = require("@/client/components/SelectProductionToLose.vue");
const ShiftAresGlobalParameters_vue_1 = require("@/client/components/ShiftAresGlobalParameters.vue");
exports.default = vue_1.default.component('player-input-factory', {
    props: {
        players: {
            type: Array,
        },
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        'and-options': AndOptions_vue_1.default,
        'or-options': OrOptions_vue_1.default,
        'select-amount': SelectAmount_vue_1.default,
        SelectCard: SelectCard_vue_1.default,
        'select-option': SelectOption_vue_1.default,
        SelectPayment: SelectPayment_vue_1.default,
        SelectProjectCardToPlay: SelectProjectCardToPlay_vue_1.default,
        SelectInitialCards: SelectInitialCards_vue_1.default,
        'select-player': SelectPlayer_vue_1.default,
        'select-space': SelectSpace_vue_1.default,
        'select-party-player': SelectPartyPlayer_vue_1.default,
        'select-party-to-send-delegate': SelectPartyToSendDelegate_vue_1.default,
        'select-colony': SelectColony_vue_1.default,
        SelectProductionToLose: SelectProductionToLose_vue_1.default,
        ShiftAresGlobalParameters: ShiftAresGlobalParameters_vue_1.default,
    },
    methods: {
        saveData() {
            this.$children[0].saveData();
        },
        canSave() {
            const canSave = this.$children[0].canSave;
            return canSave ? canSave() : true;
        },
        getComponentName(inputType) {
            switch (inputType) {
                case PlayerInputType_1.PlayerInputType.AND_OPTIONS:
                    return 'and-options';
                case PlayerInputType_1.PlayerInputType.SELECT_CARD:
                    return 'SelectCard';
                case PlayerInputType_1.PlayerInputType.SELECT_PROJECT_CARD_TO_PLAY:
                    return 'SelectProjectCardToPlay';
                case PlayerInputType_1.PlayerInputType.SELECT_INITIAL_CARDS:
                    return 'SelectInitialCards';
                case PlayerInputType_1.PlayerInputType.OR_OPTIONS:
                    return 'or-options';
                case PlayerInputType_1.PlayerInputType.SELECT_OPTION:
                    return 'select-option';
                case PlayerInputType_1.PlayerInputType.SELECT_PAYMENT:
                    return 'SelectPayment';
                case PlayerInputType_1.PlayerInputType.SELECT_SPACE:
                    return 'select-space';
                case PlayerInputType_1.PlayerInputType.SELECT_PLAYER:
                    return 'select-player';
                case PlayerInputType_1.PlayerInputType.SELECT_AMOUNT:
                    return 'select-amount';
                case PlayerInputType_1.PlayerInputType.SELECT_DELEGATE:
                    return 'select-party-player';
                case PlayerInputType_1.PlayerInputType.SELECT_PARTY_TO_SEND_DELEGATE:
                    return 'select-party-to-send-delegate';
                case PlayerInputType_1.PlayerInputType.SELECT_COLONY:
                    return 'select-colony';
                case PlayerInputType_1.PlayerInputType.SELECT_PRODUCTION_TO_LOSE:
                    return 'select-production-to-lose';
                case PlayerInputType_1.PlayerInputType.SHIFT_ARES_GLOBAL_PARAMETERS:
                    return 'shift-ares-global-parameters';
                default:
                    throw new Error('Unsupported input type: ' + inputType);
            }
        },
    },
});
