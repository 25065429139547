"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Shared_1 = require("@/client/components/overview/Shared");
exports.default = vue_1.default.extend({
    name: 'OverviewSettings',
    methods: {
        toggleTagsView() {
            this.$root.setVisibilityState('tags_concise', !this.$root.getVisibilityState('tags_concise'));
        },
        getTagToggleLabel() {
            return Shared_1.Shared.isTagsViewConcise(this.$root) ? 'full' : 'concise';
        },
    },
});
