"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardType_1 = require("@/common/cards/CardType");
const i18n_1 = require("@/client/directives/i18n");
const CardCorporationLogo_vue_1 = require("@/client/components/card/CardCorporationLogo.vue");
exports.default = vue_1.default.extend({
    name: 'CardTitle',
    props: {
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    components: {
        CardCorporationLogo: CardCorporationLogo_vue_1.default,
    },
    methods: {
        isCorporation() {
            return this.type === CardType_1.CardType.CORPORATION;
        },
        isPrelude() {
            return this.type === CardType_1.CardType.PRELUDE;
        },
        getClasses(title) {
            const classes = ['card-title'];
            if (this.type === CardType_1.CardType.AUTOMATED) {
                classes.push('background-color-automated');
            }
            else if (this.type === CardType_1.CardType.ACTIVE) {
                classes.push('background-color-active');
            }
            else if (this.type === CardType_1.CardType.EVENT) {
                classes.push('background-color-events');
            }
            else if (this.type === CardType_1.CardType.PRELUDE) {
                classes.push('background-color-prelude');
            }
            else if (this.type === CardType_1.CardType.STANDARD_PROJECT || this.type === CardType_1.CardType.STANDARD_ACTION) {
                classes.push('background-color-standard-project');
            }
            const localeSpecificTitle = (0, i18n_1.translateText)(this.getCardTitleWithoutSuffix(title));
            if (localeSpecificTitle.length > 26) {
                classes.push('title-smaller');
            }
            else if (localeSpecificTitle.length > 23) {
                classes.push('title-small');
            }
            return classes.join(' ');
        },
        getMainClasses() {
            const classes = ['card-title'];
            if (this.type === CardType_1.CardType.STANDARD_PROJECT || this.type === CardType_1.CardType.STANDARD_ACTION) {
                classes.push('card-title-standard-project');
            }
            return classes.join(' ');
        },
        getCardTitleWithoutSuffix(title) {
            return title.split(':')[0];
        },
    },
});
