"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'Button',
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        align: {
            type: String,
            validator: (align) => ['right', 'left', 'center'].includes(align),
            required: false,
            default: 'center',
        },
        size: {
            type: String,
            default: 'normal',
            validator: (item) => ['tiny', 'small', 'normal', 'big', 'jumbo'].includes(item),
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        disableOnServerBusy: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'normal',
            validator: (item) => [
                'normal',
                'action',
                'max',
                'success',
                'error',
                'submit',
                'close',
                'back',
                'minus',
                'plus',
            ].includes(item),
        },
    },
    computed: {
        isDisabledDueToServerBusy() {
            return this.disableOnServerBusy && this.$root.isServerSideRequestInProgress;
        },
        isDisabled() {
            return this.disabled || this.isDisabledDueToServerBusy;
        },
        hasIcon() {
            const iconTypes = ['close', 'back', 'plus', 'minus'];
            return iconTypes.includes(this.type);
        },
        outerClass() {
            return {
                'btn-tiny': this.size === 'tiny',
                'btn-sm': this.size === 'small',
                'btn-lg': this.size === 'big',
                'btn-max': this.type === 'max',
                'btn-plus': this.type === 'plus',
                'btn-minus': this.type === 'minus',
                'btn-success': this.type === 'success',
                'btn-error': this.type === 'error',
                'btn-action': this.type === 'action',
                'btn-submit': this.type === 'submit',
                'float-left': this.align === 'left',
                'float-right': this.align === 'right',
                'btn-rounded': this.rounded === true,
                'loading': this.isDisabledDueToServerBusy,
            };
        },
        iconClass() {
            return {
                'icon-cross': this.type === 'close',
                'icon-back': this.type === 'back',
                'icon-plus': this.type === 'plus',
                'icon-minus': this.type === 'minus',
            };
        },
    },
});
