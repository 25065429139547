"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.THE_END = exports.SPECTATOR = exports.PLAYER_INPUT = exports.PLAYER = exports.NEW_GAME = exports.LOAD_GAME = exports.LOAD = exports.HELP = exports.GAMES_OVERVIEW = exports.GAME = exports.CARDS = exports.API_WAITING_FOR = exports.API_STATS = exports.API_SPECTATOR = exports.API_PLAYER = exports.API_METRICS = exports.API_GAMES = exports.API_GAME_LOGS = exports.API_GAME_HISTORY = exports.API_GAME = exports.API_CLONEABLEGAME = exports.ADMIN = void 0;
exports.ADMIN = '/admin';
exports.API_CLONEABLEGAME = '/api/cloneablegame';
exports.API_GAME = '/api/game';
exports.API_GAME_HISTORY = '/api/game/history';
exports.API_GAME_LOGS = '/api/game/logs';
exports.API_GAMES = '/api/games';
exports.API_METRICS = '/api/metrics';
exports.API_PLAYER = '/api/player';
exports.API_SPECTATOR = '/api/spectator';
exports.API_STATS = '/api/stats';
exports.API_WAITING_FOR = '/api/waitingfor';
exports.CARDS = '/cards';
exports.GAME = '/game';
exports.GAMES_OVERVIEW = '/games-overview';
exports.HELP = '/help';
exports.LOAD = '/load';
exports.LOAD_GAME = '/load_game';
exports.NEW_GAME = '/new-game';
exports.PLAYER = '/player';
exports.PLAYER_INPUT = '/player/input';
exports.SPECTATOR = '/spectator';
exports.THE_END = '/the-end';
