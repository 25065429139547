"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sum = exports.inplaceRemove = exports.generateClassString = exports.intersection = exports.range = exports.playerColorClass = void 0;
const playerColorClass = (color, type) => {
    const prefix = {
        shadow: 'player_shadow_color_',
        bg_transparent: 'player_translucent_bg_color_',
        bg: 'player_bg_color_',
    }[type];
    return `${prefix}${color}`;
};
exports.playerColorClass = playerColorClass;
const range = (n) => Array.from(Array(n).keys());
exports.range = range;
function intersection(a, b) {
    return a.filter((e) => b.includes(e));
}
exports.intersection = intersection;
const generateClassString = (classes) => classes.join(' ').trimStart();
exports.generateClassString = generateClassString;
function inplaceRemove(array, element) {
    const idx = array.findIndex((e) => e === element);
    if (idx > -1) {
        array.splice(idx, 1);
    }
}
exports.inplaceRemove = inplaceRemove;
function sum(array) {
    return array.reduce((a, b) => a + b, 0);
}
exports.sum = sum;
